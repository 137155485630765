import { getRandomInt } from "../utils/utils";
import lottie from "lottie-web";


export default class SceneModal {
    constructor(el)
    {
        this.element = el;

        this.illustrationArray = ['s1', 's2', 's3', 's5', 's6', 's7', 's8', 's9'];

        this.illustration = this.element.querySelector('.modal__illustration');

        this.getRandom();

        this.animation = lottie.loadAnimation(
            {
                container : this.illustration,
                renderer: "svg",
                loop: true,
                autoplay: true,
                path : window.location.href + `/lottie/modals/${this.activeIllustration}.json`,
                rendererSettings: {
                    className : 'animation-blue',
                    progressiveLoad: true,
                }
            }
        )
    }
    getRandom()
    {
        let int = getRandomInt(0, this.illustrationArray.length - 1);

        this.activeIllustration = this.illustrationArray[int];

    }
}