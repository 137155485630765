import { gsap } from "gsap/all";
import { getRandomInt } from "../utils/utils";

export default class ShareModal {
    constructor()
    {
        this.nav = document.querySelector('.nav');

        this.element = document.querySelector('.modal--share');
        this.wrapper = document.querySelector('.modal--share__wrapper');
        this.activeModal = 'none';

        gsap.set(this.wrapper, {
            y: '-=200%',
            rotate: 8
        })

        this.toggleButton = document.querySelector('.nav__share');

        this.isOpen = false;

        this.permalink = window.location.href;

        this.socialLinks = document.querySelectorAll('.nav__socials a');

        this.closeButton = document.querySelector(".modal__close");

        this.navCatch = document.querySelector(".nav__catch--modal");

        this.toggleArray = [this.navCatch, this.closeButton, this.toggleButton];

        this.toggleArray.forEach((item) => item.addEventListener('click', ()=>
        {
            this.toggle()
        }))


        this.socialLinks.forEach((link)=> 
        {
            let parent = link.parentNode;

            if (parent.classList.contains('twitter'))
            {
                link.href = "https://twitter.com/intent/tweet?url=" + this.permalink + '&text=Explore%20@oldvictheatre%27s%20%23TheOVHub%20for%20yourself%20to%20discover%20more%20about%20the%20world%20of%20theatre';
            }
            if (parent.classList.contains('whatsapp'))
            {
                link.href = "https://api.whatsapp.com/send?text=" + this.permalink;
            }
            if (parent.classList.contains('facebook'))
            {
                link.href = "https://www.facebook.com/sharer/sharer.php?u=" + this.permalink;
            }
            if (parent.classList.contains('linkedin'))
            {
                link.href = "https://www.linkedin.com/shareArticle?mini=true&url=" + this.permalink;
            }
        })

        this.copyLink = document.querySelector('.nav__copy-link');
        this.copyLink.textContent = window.location.href;
    
        this.copyButton = document.querySelector('.nav__copy-icon');
        
        this.copyButton.addEventListener('click', (e) => this.copy(e.target));
    }
    copy(target)
    {
    let copyText = window.location.href;
    if (navigator.clipboard)
    {
        navigator.clipboard.writeText(copyText);
    }

    target.closest('.nav__copy').classList.add('copying');

    setTimeout(()=>
    {
        target.closest('.nav__copy').classList.remove('copying');
        document.querySelector('.nav__copy-text').textContent = 'URL copied!'

    }, 500);

    }
    show()
    {
        let rotation = getRandomInt(-8, 8);
        if (!this.isOpen)
        {

            window.app.pauseScroll();
            window.app.cursor.shrink();

            this.activeModal = document.body.dataset.activeModal;

            gsap.fromTo(this.wrapper, 
            {
                y: '-200%',
                rotate: rotation
            }, 
            {
                y: "0%",
                rotate: 0,
                transition: "elastic.inOut(1,0.5)",
                duration: 0.5,
            })

            document.body.dataset.activeModal = 'none';

        } else
        {
            window.app.enableScroll();
            window.app.cursor.grow();

            gsap.to(this.wrapper, 
                {
                    y: '-200%',
                    rotate: rotation,
                    transition: "elastic.inOut(1,0.5)",
                    duration: 0.5
                })

            this.nav.removeEventListener('click', () => this.toggle());

            document.body.dataset.activeModal = this.activeModal;
        }
    }
    toggle()
    {
        this.show();
        this.isOpen = !this.isOpen;
        document.body.classList.toggle('modal--open');
    }
}