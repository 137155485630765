import "./styl/style.styl";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import Cursor from "./components/Cursor";
import Scene from "./components/Scene";
import Nav from "./components/Nav";
import Loader from "./components/Loader";
import ShareModal from "./components/ShareModal"
import SceneModal from "./components/SceneModal";
import ScrollTrigger from "gsap/ScrollTrigger";
import Button from "./components/Button";
import VideoController from "./components/VideoController";
import MobilePanel from "./components/MobilePanel";
import EndModal from "./components/EndModal";

let instance = null;

export default class App {
    constructor()
    {
        if(instance)
        {
            return instance;
        }
        instance = this;
    
        window.app = this;

        gsap.registerPlugin(ScrollTrigger, SplitText);

        this.checkMobile();

        this.vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${this.vh}px`);

        window.addEventListener('resize', ()=>
        {
            this.checkMobile();
            this.vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${this.vh}px`);
            this.updateActiveScene(document.body.dataset.activeScene);
            this.getSplitText();
            this.cursor.init();
        })

        const checkScroll = ()=>
        {
            document.body.classList.add('is-scrolled');
            window.removeEventListener('scroll', checkScroll);
        }

        window.addEventListener('scroll', checkScroll , { passive: true })

        this.shareModal = new ShareModal();

        this.buttons = new Button();

        this.sceneModals = document.querySelectorAll('.modal--scene');

        this.sceneModals.forEach((modal) => new SceneModal(modal));

        this.cursor = new Cursor();

        this.nav = new Nav();
    
        this.scenes = gsap.utils.toArray('.section');

        this.loadedScenes = [];

        this.loader = new Loader;

        this.videoController = new VideoController;
        
        this.mobilePanel = new MobilePanel();

        this.activeScene;

        this.endModal = new EndModal();

        this.mm = gsap.matchMedia();

        this.getNavHeight();

        let self = this;

        this.updateActiveScene(document.body.dataset.activeScene);
  
        this.scenes.forEach((scene)=>
        {
    
            let id = scene.getAttribute('data-scene');

            this.loader.addItem(id);
            let animation = new Scene({section: id});
            animation.on('loaded', () => {
                self.loader.setLoaded(id);
                self.loadedScenes.push(animation);
            })
            
        })

        this.loader.on('complete', () => {

            document.querySelector('body').classList.remove('loading');
            document.querySelector('body').style.visibility = 'visible';
            document.querySelector('nav').style.visibility = 'visible';
            document.querySelector('main').style.visibility = 'visible';

            window.app.loadedScenes.forEach((scene)=>
            {
                if (scene.scene === 'setting-the-stage')
                {
                    scene.lottieScroll.navEnter();
                    scene.lottieScroll.videoEnter();
                }
            })
            // window.app.loadedScenes[0].lottieScroll.navEnter();
            // window.app.loadedScenes[0].lottieScroll.videoEnter();
            
            document.querySelector('body').classList.add('loaded');

            this.mm.add("(max-width: 1024px)", () =>
            {
                setTimeout(()=>
                {
                    window.app.loadedScenes.forEach((scene)=>
                    {
                        if (scene.lottieScroll.video)
                        {
                            scene.lottieScroll.loadVideo(scene.lottieScroll.video);
                        }
                    })

                },1000)
                
                this.getSplitText();

            })

        })
    
    }
    preventScroll(e)
    {
        e.preventDefault()
        e.stopPropogation();

        return false;
    }
    pauseScroll()
    {
        this.scrollTriggerArray = ScrollTrigger.getAll();
        this.scrollTriggerArray.forEach((st) => {
            st.disable(false);
        })

        let topScroll = window.pageYOffset || document.documentElement.scrollTop;
        let leftScroll = window.pageXOffset || document.documentElement.scrollLeft;
        
        window.onscroll = function() 
        {
            window.scrollTo(leftScroll, topScroll);
        };
    }
    enableScroll()
    {
        this.scrollTriggerArray = ScrollTrigger.getAll();
        this.scrollTriggerArray.forEach((st) => {
            st.enable(false);
        })

        window.onscroll = function() {};

    }
    getSplitText()
    {
        if (this.splitText)
        {
            this.splitText.revert();
        }

        if (this.isMobile)
        {
            this.splitText = new SplitText('.text h3', {
                type: "lines",
                linesClass: "cursor__line",
                lineThreshold: 1,
            })

            this.cursor.init();
        }
    }
    getNavHeight()
    {

        let totalHeight = 0;

        let heights = 
        {
            logo : parseInt(document.querySelector('.nav__logo').offsetHeight),
            topPadding : parseInt(getComputedStyle(document.documentElement).getPropertyValue('--nav-vpad')),
        }

        for (const height in heights)
        {
            totalHeight += heights[height];
        }

        if (this.isMobile)
        {
            totalHeight *= 1.25;
        }

        return totalHeight;
    }
    getFooterHeight()
    {
        let totalHeight = 0;

        let heights = 
        {
            copyright : parseInt(document.querySelector('.nav__copyright').offsetHeight),
            bottomPadding : parseInt(getComputedStyle(document.documentElement).getPropertyValue('--nav-vpad')),
        }

        for (const height in heights)
        {
            totalHeight += heights[height];
        }

        if (this.isMobile)
        {
            totalHeight *= 1.25;
        }

        return totalHeight;

    }
    checkMobile()
    {
        let accordion = document.querySelector(".nav__accordion");

        if(window.matchMedia("(max-width: 1024px)").matches)
        { 
            this.isMobile = true;
            accordion.ariaExpanded = false;
        } else {
            this.isMobile = false;
            accordion.ariaExpanded = true;
        }
    }
    getSceneObject(id)
    {
        let sceneObject;

        this.loadedScenes.forEach((scene)=>
        {
            if (scene.scene == id)
            {
                sceneObject = scene;
            }
        })

        return sceneObject;
    }
    updateActiveScene(id)
    {
        this.activeScene = this.getSceneObject(id);
        document.body.dataset.activeScene = id;        
        this.mobilePanel.setPanel(this.activeScene);
        return this.activeScene;
    }
}