import EventEmitter from "./EventEmitter";
import { gsap } from "gsap";
import lottie from "lottie-web";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import VideoPlayer from "./VideoPlayer";
import { convertRange } from "../utils/utils";

export default class LottieScroll extends EventEmitter {
    constructor(props)
    {
        
        super()

        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(ScrollToPlugin);

        this.playhead = {frame: 0};
        this.id = props.id;

        this.section = document.getElementById(`${this.id}`);

        this.navItem = document.querySelector(`#${props.id}-nav`);

        this.mobileProgress = document.querySelector(`#${props.id}-progress-mobile`);

        this.target = gsap.utils.toArray(props.target)[0];

        this.video = document.querySelector(`[data-scene-video="${this.id}"`);
        
        this.text = this.section.querySelector('.text');
        
        this.isVideoLoaded = false;

        this.modalHasShown = [];

        this.nextSection = this.section.nextSibling;
        this.nextSectionId = this.nextSection && this.nextSection.id;
        this.nextSectionVideo = this.nextSectionId && document.querySelector(`[data-scene-video="${this.nextSectionId}"`);

        this.speeds = 
        {
            slow: "+=1500%", 
            medium: "+=1000%", 
            fast: "+=500"
        }

        this.triggerPoints = props.triggerPoints;

        this.spacer = this.section.querySelector('.spacer');

        let mm = gsap.matchMedia();

        mm.add("(min-width: 1024px)", (context) => 
        {
           
            this.st = 
            {
                anticipatePin: 1,
                trigger: this.target, 
                pin: true, 
                pinType: 'fixed',
                start: "top top", 
                end: this.speeds[props.speed] || " +=1000", 
                scrub: 1.5, 
                onEnter: () => this.animationEnter(), 
                onEnterBack: () => this.animationEnter(), 
                onLeave: () => this.animationLeave(), 
                onLeaveBack: () =>this.animationLeave(),
                onUpdate: self => {
                    this.progress = (self.progress*100).toFixed(3);
                    this.checkProgress(this.progress)
                },
            }

            for (let p in props) 
            {   //override scrollTrigger defaults
                this.st[p] = props[p];
            }

            if (!this.animation)
            {
                this.animation = lottie.loadAnimation(
                    {
                        container: document.getElementById('animation'),
                        renderer: props.renderer || "svg",
                        loop: false,
                        autoplay: false,
                        path: props.path,
                        rendererSettings: {
                            id : props.id + '-animation',
                            progressiveLoad: true,
                        }
                });
            }
            else
            {
                let currentAnimation = document.getElementById(`${this.id}-animation`);

                currentAnimation.remove();

                this.animation = lottie.loadAnimation(
                    {
                        container: document.getElementById('animation'),
                        renderer: props.renderer || "svg",
                        loop: false,
                        autoplay: false,
                        path: props.path,
                        rendererSettings: {
                            id : props.id + '-animation',
                            progressiveLoad: true,
                        }
                });


            }
            
            let self = this;

            context.add("animationLoaded", () =>
            {
                
                self.setLoaded();

                gsap.to(self.playhead, {
                    frame: self.animation.totalFrames - 1,
                    ease: "none",
                    onUpdate: () => self.animation.goToAndStop(self.playhead.frame, true),
                    scrollTrigger: self.st
                });

                gsap.to(`#${props.id}-progress`, {
                    scrollTrigger: {
                        trigger: `#${props.id}`,
                        start: 'top top',
                        end: 'bottom top',
                        scrub: true,
                        onEnter: () => self.navEnter(),
                        onEnterBack: () => self.navEnter(),
                        onLeave: () => self.navLeave(),
                        onLeaveBack: () => self.navLeave(),
                    },
                    x: "+=100%",
                    ease: 'none'
                })
                ScrollTrigger.normalizeScroll();
                ScrollTrigger.sort();
                ScrollTrigger.refresh(); 
            })



            this.animation.addEventListener("DOMLoaded", context.animationLoaded);
            
            return () =>
            {
                this.animation.removeEventListener("DOMLoaded", context.animationLoaded);
            }
        })

        mm.add("(max-width: 1024px)", () =>
        {
       
            document.addEventListener('readystatechange', ()=>
            {
                this.setLoaded();
            });

            ScrollTrigger.create({
                trigger: `#${props.id}`,
                start: 'top top',
                end: 'bottom top',
                onEnter: () => this.navEnter(),
                onEnterBack: () => this.navEnter(),
                onLeave: () => this.navLeave(),
                onLeaveBack: () => this.navLeave(),
            });

            ScrollTrigger.create({
                trigger: this.spacer,
                start: 'top top',
                end: 'bottom bottom',
                onUpdate: self => {
                    this.progress = (self.progress*100).toFixed(3);
                    this.updateSpacerProgress(this.progress)
                },
            });


            ScrollTrigger.create({
                trigger: this.text,
                start: 'top 80%',
                end: 'bottom top',
                onEnter: () => this.textEnter(),
                onEnterBack: () => this.textEnter(),
                onLeave: () => this.textLeave(),
                onLeaveBack: () => this.textLeave(),
            })

            ScrollTrigger.create({
                trigger: this.text,
                start: 'top bottom',
                end: 'bottom top',
                onUpdate: self => {
                    this.progress = (self.progress*100).toFixed(3);
                    this.checkTextProgress(this.progress)
                },
            })
    
        })
    }
    updateSpacerProgress(progress)
    {

        this.section.dataset.spacerProgess =`${progress}%`

        let value = convertRange(progress, [0, 100], [0, -500]);
    }
    textEnter()
    {
        document.body.dataset.textOverlay = true;
    }
    textLeave()
    {
        document.body.dataset.textOverlay = false;
    }
    checkTextProgress(progress)
    {
        // use progress between 0-49 to current video (parent) opacity to 0

        if (progress < 49)
        {
            let value = convertRange(progress, [0, 49], [1, 0]);
            gsap.to(this.video, 
                {
                    opacity: value
                })

            gsap.to(this.nextSectionVideo,{
                opacity: 0
            })
        }

        else if (progress > 49)
        {

            gsap.to(this.video, 
                {
                    opacity: 0
                })
            window.app.videoController.restartCurrent()

            let value = convertRange(progress, [51, 100], [0, 1]);

            if (this.nextSectionVideo)
            {
                gsap.to(this.nextSectionVideo, 
                    {
                        opacity: value
                    })
            }
        }


        // use progress between 51 - 100 to next video opacity to 1 (if exists)

        
    }
    checkProgress(progress)
    {

        this.section.dataset.progress = `${progress}%`

        this.triggerPoints.forEach((triggerPoint) => 
        {
            let action = triggerPoint[2];

            if (progress >= triggerPoint[0] && progress <= triggerPoint[1])
            {
                this.activeModal(triggerPoint[3]);
            }
            else 
            {
                return;
            }
        })
        
    }
    activeColor(color)
    {
        document.body.dataset.activeColor = color;
    }
    activeModal(modal)
    {
       
        let activeModal = modal;
        let activeScene = document.body.dataset.activeScene;

        if (activeModal !== 'none')
        {
            let sceneSection = document.querySelector(`[data-scene-modal="${activeScene}"]`);
            let sceneModal;
            if (sceneSection)
            {
                sceneModal = sceneSection.querySelector(`[data-modal="${activeModal}"]`);
            }

            if (window.app.isMobile)
            {
            
                if (!this.modalHasShown.includes(modal) && !document.body.classList.contains('skipping'))
                {
                    let allModals = document.querySelectorAll('.modal--scene').forEach((modal)=>
                    {
                        modal.ariaExpanded = false;
                    })
                    document.body.dataset.videoPaused = true;
                    this.videoPlayer.pause();
                    document.body.dataset.activeModal = modal;
                    sceneModal.ariaExpanded = true;
                    this.modalHasShown.push(modal);

                    const onAction = () =>
                    {
                        this.videoPlayer.resume();
                        document.body.dataset.activeModal = 'none';
                        document.body.dataset.videoPaused = false;
                        sceneModal.ariaExpanded = false;
                        window.removeEventListener('click', onAction)
                        window.removeEventListener('scroll', onAction)
                    }
                    window.addEventListener('click', onAction)
                    window.addEventListener('scroll', onAction )
                } 
            } 
            else
            {
                let allModals = document.querySelectorAll('.modal--scene').forEach((modal)=>
                {
                    modal.ariaExpanded = false;
                })
                let sceneSection = document.querySelector(`[data-scene-modal="${activeScene}"]`);
                let sceneModal = sceneSection.querySelector(`[data-modal="${activeModal}"]`);
                document.body.dataset.activeModal = modal;
                sceneModal.ariaExpanded = true;
            }
        } else
        {
            document.body.dataset.activeModal = modal;
        }
    }
    pause()
    {
        ScrollTrigger.disable(false);
    }
    resume()
    {
        ScrollTrigger.enable(false);
    }
    animationEnter()
    {
        let animations = document.querySelectorAll('#animation svg');

        animations.forEach((animation)=>
        {
            gsap.to(animation, {
                opacity: 0
            })
        })

        gsap.to(`#${this.id}-animation`, {
        opacity: 1,
        });

        window.app.enableScroll()
        document.body.dataset.activeAnimation = true;
        document.body.dataset.textOverlay = false;
    }
    videoEnter()
    { 
        if (this.video && window.app.isMobile)
        {
            this.loadVideo(this.video);
            window.app.videoController.pauseAll();
            this.videoPlayer.restart();
        }

    }
    loadVideo(video)
    {
        if (!this.isVideoLoaded)
        {
            for (let source in this.video.children){
                let videoSource = this.video.children[source];
                if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE"){
                    videoSource.src = videoSource.dataset.src;
                }
                }
                video.load();
                video.classList.remove("lazy");
                this.videoPlayer = new VideoPlayer(video, this.id);
                this.isVideoLoaded = true;
                this.videoPlayer.restart();
                this.videoPlayer.on('time-elapsed', ()=>
                {
                    this.checkProgress(this.videoPlayer.percentageProgress)
                })
        }

    }
    animationLeave()
    {
        if (!document.body.classList.contains('transition'))
        {
            gsap.to(`#${this.id}-animation`, {
                opacity: 0.2
            })
            document.body.dataset.activeAnimation = false;
            document.body.dataset.textOverlay = true;
        }
    }
    setLoaded()
    {
        this.trigger('loaded');
    }
    navEnter()
    {
        window.app.updateActiveScene(this.id);
        window.app.nav.clear();
        this.navItem.classList.add('active');
        this.navItem.querySelector('.nav__progress-bar').ariaBusy = true;
        window.app.cursor.clearHovers();
    }
    navLeave() 
    {
        this.navItem.classList.remove('active');
        this.navItem.querySelector('.nav__progress-bar').ariaBusy = false;
        this.mobileProgress.classList.remove('is-active');
        this.mobileProgress.ariaBusy = false;
    }

}