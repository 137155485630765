
import { gsap } from "gsap/all";
import { getRandomInt } from "../utils/utils";

export default class EndModal {
    constructor()
    {

        this.element = document.querySelector('.modal--end');
        this.wrapper = document.querySelector('.modal--end__wrapper');
        this.trigger = document.querySelector('.end-trigger')

        gsap.set(this.wrapper, {
            y: '-=200%',
            rotate: 8
        })

        this.isOpen = false;

        this.restartButton = document.querySelector('.button--encore');

        this.restartButton.addEventListener('click', (e)=>
        {

            e.preventDefault();

            this.toggle();

            let startScene = document.querySelector("#setting-the-stage-nav");

            startScene.click();
        });

        document.addEventListener('scroll', ()=>
        {
            let scrollAmount = document.documentElement.scrollTop || document.body.scrollTop;
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

            let scrollValue = scrollAmount / ( scrollHeight - clientHeight);

            if (scrollValue >= 1)
            {
                this.toggle();
            }

        })

       
    }
    show()
    {
        let rotation = getRandomInt(-8, 8);
        if (!this.isOpen)
        {
            window.app.pauseScroll();
            window.app.cursor.shrink();

            this.activeModal = document.body.dataset.activeModal;

            gsap.fromTo(this.wrapper, 
            {
                y: '-200%',
                rotate: rotation
            },  
            {
                y: "0%",
                rotate: 0,
                transition: "elastic.inOut(1,0.5)",
                duration: 0.5,
            })

            document.body.dataset.activeModal = 'none';

        } else
        {
            window.app.enableScroll();
            window.app.cursor.grow();

            gsap.to(this.wrapper, 
                {
                    y: '-200%',
                    rotate: rotation,
                    transition: "elastic.inOut(1,0.5)",
                    duration: 0.5
                })

            document.body.dataset.activeModal = this.activeModal;
        }
    }
    toggle()
    {
        if (!document.body.classList.contains('transition'))
        {
            this.show();
            this.isOpen = !this.isOpen;
            document.body.classList.toggle('modal--open');
        }
    }
}