import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export default class Nav {
    constructor()
    {

        this.activeModal = 'none';
        this.isOpen = false;

        gsap.registerPlugin(ScrollToPlugin);

        this.progressBars = gsap.utils.toArray('.nav__progress-bar');
        this.progressBars.forEach((bar)=>{
            gsap.set(bar, {
                x: "-=100%"
            })
        })
    
        this.navItems = gsap.utils.toArray('.nav__item');
        this.navItems.forEach((navItem)=>{
    
            let scrollId = navItem.id.substring(0, navItem.id.length - 4).trim();
    
            navItem.addEventListener('click', () =>
            {

                const removeTransition = () =>
                {
                    window.app.updateActiveScene(scrollId);
                    document.body.classList.remove('transition');
                    // window.app.activeScene.lottieScroll.animationEnter();
                }

                const closeNav = () => 
                {
                    this.isOpen = false;
                    document.querySelector('.nav__hamburger').classList.remove('is-active');
                    document.body.classList.remove('nav--open')
                }

                const skipVideo = ()=>
                {
                    if (window.app.isMobile)
                    {
                        window.app.videoController.skipCurrent();
                    } else
                    {

                    }
                }

                document.body.classList.add('transition');

                window.app.enableScroll();

                let offset;

                if (!window.app.isMobile)
                {
                    offset = -250
                } else
                {
                    offset = -50
                }

                let tl = gsap.timeline();

                tl.to(document.querySelector('#animation'), 
                {
                    opacity: 0,
                    transition: "Power3.easeOut",
                    duration: 0.25,
                })

                tl.call(skipVideo);
            
                tl.to(document.querySelector('.nav__accordion'), 
                {
                    x: "-200%",
                    transition: "Power3.easeOut",
                    duration: 0.25,
                })

                tl.set(window, {
                    scrollTo: {
                        y: `#${scrollId}`,
                    },
                    duration: 0,
                    ease: 'none'
                })

                // tl.call(setOpacity);

                tl.call(closeNav)

                tl.to(document.querySelector('.nav__accordion'), 
                {
                    x: "0%",
                    transition: "Power3.easeOut",
                    duration: 0.25,
                })

                tl.call(removeTransition)

                tl.to(window, {
                    scrollTo: {
                        y: `#${scrollId}`,
                        offsetY: offset,
                    },
                    duration: 0.5,
                    ease: 'none'
                })

                tl.to(document.querySelector('#animation'), 
                {
                    opacity: 1,
                    transition: "Power3.easeOut",
                    duration: 0.25,
                })
                

    
            })
        });

        this.navIcon = document.querySelector(".nav__hamburger");
        this.navIcon.addEventListener('click', (e)=>
        {
            let accordion = document.querySelector(".nav__accordion");
            let animation = document.getElementById(`${this.activeScene}-animation`);
            let video = document.querySelector(`[data-scene-video="${this.activeScene}"]`);

            if (!this.isOpen)
            {
                this.activeScene = document.body.dataset.activeScene;
                this.activeModal = document.body.dataset.activeModal;
                document.body.dataset.activeModal = 'none';
                window.app.pauseScroll();
                accordion.ariaExpanded = true;
                document.body.classList.remove('modal--open');
            } else
            {
                document.body.dataset.activeModal = this.activeModal;
                window.app.enableScroll();
                accordion.ariaExpanded = false;
            }
            this.isOpen = !this.isOpen;
            e.target.classList.toggle('is-active');
            document.body.classList.toggle('nav--open')
        })

    }
    clear()
    {
        this.navItems.forEach((item) => item.classList.remove('active'));
    }   
}