export default class VideoController
{
    constructor()
    {
        this.isPlaying = false;

        this.skipButton = document.querySelector('.nav__mobile-indicator--action');

        this.skipButton && this.skipButton.addEventListener('click', ()=>
        {
            this.skipCurrent();
        })

        this.restartButton = document.querySelector('.nav__mobile-indicator--restart');

        this.restartButton && this.restartButton.addEventListener('click', ()=>
        {
           this.restartCurrent();
        })

        this.catch = document.querySelector(".nav__catch--video");

        this.catch && this.catch.addEventListener('click', ()=>
        {
            this.playCurrent();
        })

        this.updateCurrent();

        this.pauseButton = document.querySelector('.nav__mobile-indicator--pause');

        this.pauseIcon = document.querySelector('.nav__scroll-icon--pause');

        this.pauseButton && this.pauseButton.addEventListener('click', ()=>
        {
            this.pauseCurrent();
        })
    }
    playCurrent()
    {
        this.updateCurrent();
        this.currentScene.lottieScroll.videoPlayer.play();
    }
    pauseCurrent()
    {
        if (this.currentScene.lottieScroll.videoPlayer.isPlaying)
        {
            this.pauseIcon.classList.add("paused");
            this.currentScene.lottieScroll.videoPlayer.pause();
        } else
        {
            this.pauseIcon.classList.remove("paused");
            this.currentScene.lottieScroll.videoPlayer.play();
        }
    }
    restartCurrent()
    {
        this.updateCurrent();
        if (this.currentScene.lottieScroll && this.currentScene.lottieScroll.videoPlayer)
        {
            this.currentScene.lottieScroll.videoPlayer.restart();
            this.pauseIcon.classList.remove("paused");
        } 
        else if (this.currentScene && this.currentScene.lottieScroll)
        {
                this.currentScene.lottieScroll.loadVideo(this.currentScene.lottieScroll.video);
        }
    }
    skipCurrent()
    {
        this.updateCurrent();
        this.pauseIcon.classList.remove("paused");
        this.currentScene.lottieScroll.videoPlayer.skip();
    }
    pauseAll()
    {
        window.app.scenes.forEach((scene)=>
        {
            this.pauseIcon.classList.remove("paused");
            scene.lottieScroll && scene.lottieScroll.videoPlayer.pause();
        })

    }
    updateCurrent()
    {
        this.currentScene = window.app.activeScene;
    }
}