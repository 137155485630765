import { gsap } from "gsap/all";
import EventEmitter from "./EventEmitter";

export default class VideoPlayer extends EventEmitter {
    constructor(video, id)
    {
        super()

        this.video = video;
        this.progressElement = document.querySelector(`#${id}-progress-mobile`);

        this.video.controls = false;

        this.duration = this.video.duration;
        this.timeElapsed;
        this.percentageProgress = ((this.timeElapsed / this.duration) * 100).toFixed(3);

        this.video.addEventListener('timeupdate', () => this.updateTimeElapsed());

        this.video.addEventListener('ended', () => this.ended());

        this.isPlaying = false;

        this.setState('video-start');
    }
    updateTimeElapsed()
    {
        this.duration = this.video.duration;
        this.timeElapsed = this.video.currentTime;
        this.percentageProgress = ((this.timeElapsed / this.duration) * 100).toFixed(3);

        if (this.percentageProgress <= 100 && this.percentageProgress >= 0)
        {
            this.trigger('time-elapsed');
            this.progressElement.classList.add('is-active');
            this.progressElement.ariaBusy = true;

            gsap.to(this.progressElement, 
            {
                x: `${Math.min(0 - (100 - this.percentageProgress),0)}%`,
                ease: "none"
            })
        }
       
        if (this.percentageProgress < 1)
        {
            this.setState('video-start');
        }
        if (this.percentageProgress > 1 && this.percentageProgress < 95)
        {
            this.setState('video-progress');
        }
        if (this.percentageProgress > 95)
        {
            this.setState('video-end');
        }
    }
    play()
    {
        this.video.play();
        this.isPlaying = true;
    }
    resume()
    {
        this.video.play();
    }
    restart()
    {
        this.video.pause();
        this.video.playbackRate = 1;
        this.video.currentTime = 0;
        document.body.dataset.activeModal = 'none';
        if (window.app.activeScene)
        {
            window.app.activeScene.lottieScroll.modalHasShown = [];
        }
        this.updateTimeElapsed();
        this.setState('video-start');
    }
    pause()
    {
        this.video.pause();
        this.isPlaying = false;
    }
    ended()
    {
        document.body.classList.remove('skipping');
        document.body.dataset.activeModal = 'none';
    }
    skip()
    {
        this.pause()
        document.body.classList.add('skipping');
        document.body.dataset.activeModal = 'none';
        this.video.currentTime = this.video.duration - this.video.duration * 0.05;
        this.play();
        this.updateTimeElapsed();
    }
    setState(state)
    {
        document.body.dataset.videoState = state;
        if (state = 'video-start')
        {
            this.state = state;
        }
        if(state = 'video-progress')
        {
            this.state = state;
        }
        if(state = 'video-ended')
        {
            this.state = state;
        }
    }
}