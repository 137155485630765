import EventEmitter from "./EventEmitter";
import LottieScroll from "./LottieScroll";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import scrollama from "scrollama";


export default class Scene extends EventEmitter {
    constructor(props)
    {
        super()

        gsap.registerPlugin(ScrollTrigger);

        this.section = document.querySelector(`[data-scene='${props.section}']`);

        this.target = this.section.querySelector('.spacer');

        this.scene = this.section.getAttribute('data-scene');

        this.makeObserver();

        // this.scroller = new scrollama()
        
        window.onresize = () => 
        {
            this.makeObserver();
        }
        
        this.configScene(this.scene);  
    }
    makeObserver()
    {
        const topHeight = window.app.getNavHeight();

        const bottomHeight = window.app.getFooterHeight();

        const options = 
        {
            text: 
            {
                rootMargin: `-${topHeight}px 0px -${bottomHeight}px 0px`,
                threshold: 0.000001,
                root: document.body,
            },
            section:
            {
                rootMargin: `0% 0% -20% 0%`,
                threshold: 0.000001,
                root: document.body,
            },
            video:
            {
                rootMargin: '0% 0% 0% 0%',
                threshold: 0.000001,
                root: null,
            }
        }
        
        let mm = gsap.matchMedia();

        mm.add("(max-width: 1024px)", () => 
        {
            if (this.section.querySelector('.spacer'))
            {
                ScrollTrigger.create({
                    trigger: this.section.querySelector('.spacer'),
                    onEnter: () => this.toggleVideo(),
                    onEnterBack: () => this.toggleVideo(),
                })
            }
        })


    }
    toggleVideo()
    {
        if (this.lottieScroll)
        {
            window.app.videoController.updateCurrent();
            this.lottieScroll.videoEnter()
        }
    }
    configScene(scene)
    {

        let self = this;

        switch(scene)
        {
            case 'setting-the-stage':     
                this.lottieScroll = new LottieScroll(
                {
                    target: this.target,
                    path:  window.location.href + "/lottie/scenes/s1.json",
                    speed: "medium",
                    scrub: 1,
                    id: 'setting-the-stage',
                    triggerPoints:
                    [
                        [1, 99, 'modal', 'none'],
                    ]
                });

                this.lottieScroll.on('loaded', () => {
                    self.trigger('loaded');
                });

                this.title = 'Setting The Stage';
                this.sceneNumber = '01';
                break;
            case 'front-of-house':     
                this.lottieScroll = new LottieScroll(
                {
                    target: this.target,
                    path:  window.location.href + "/lottie/scenes/s2.json",
                    speed: "medium",
                    scrub: 1,
                    id: 'front-of-house',
                    triggerPoints:
                    [
                        [1, 99, 'modal', 'none'],
                    ]
                });

                this.lottieScroll.on('loaded', () => {
                    self.trigger('loaded');
                });

                this.title = 'Front Of House';
                this.sceneNumber = '02';

                break;
            case 'the-auditorium':
                this.lottieScroll = new LottieScroll(
                {
                    target: this.target,
                    path:  window.location.href + "/lottie/scenes/s3.json",
                    speed: "medium",
                    scrub: 1,
                    id: 'the-auditorium',
                    triggerPoints:
                    [
                        [1, 19, 'modal', 'none'],
                        [20, 29, 'modal', 'one' ],
                        [30, 39, 'modal', 'none'],
                        [40, 55, 'modal', 'two' ],
                        [56, 99, 'modal', 'none'],
                    ]
                });

                this.lottieScroll.on('loaded', () => {
                    self.trigger('loaded');
                })

                this.title = 'The Auditorium';
                this.sceneNumber = '03';

                break;
            case 'the-stage':
                this.lottieScroll= new LottieScroll(
                {
                    target: this.target,
                    path:  window.location.href + "/lottie/scenes/s4.json",
                    speed: "slow",
                    scrub: 1,
                    id: 'the-stage',
                    triggerPoints:
                    [
                        [1, 14.9, 'modal', 'none'],
                        [15, 23, 'modal', 'one' ],
                        [26, 28, 'modal', 'none'],
                        [29, 31, 'modal', 'two' ],
                        [32, 37, 'modal', 'none'],
                        [38, 44, 'modal', 'three' ],
                        [44, 50, 'modal', 'none'],
                        [51, 56, 'modal', 'four'],
                        [57, 62, 'modal', 'none'],
                        [63, 67, 'modal', 'five'],
                        [75, 80, 'modal', 'none'],
                        [81, 86, 'modal', 'six'],
                        [87, 99, 'modal', 'none'],
                    ]
                });

                this.lottieScroll.on('loaded', () => {
                    self.trigger('loaded');
                })

                this.title = 'The Stage';
                this.sceneNumber = '04';
                break;
            case 'lighting':
                this.lottieScroll= new LottieScroll(
                {
                    target: this.target,
                    speed: "medium",
                    path:  window.location.href + "/lottie/scenes/s5.json",
                    scrub: 1,
                    id: 'lighting',
                    triggerPoints:
                    [
                        [1, 21, 'modal', 'none'],
                        [22, 36, 'modal', 'one' ],
                        [37, 41, 'modal', 'none'],
                        [42, 47, 'modal', 'two' ],
                        [47.1, 47.9, 'modal', 'none'],
                        [48, 59, 'modal', 'three'],
                        [59.1, 65, 'modal', 'none'],
                        [66, 70, 'modal', 'four' ],
                        [71, 79, 'modal', 'none'],
                        [80, 88, 'modal', 'five' ],
                        [89, 99, 'modal', 'none'],
                    ]
                });

                this.lottieScroll.on('loaded', () => {
                    self.trigger('loaded');
                })

                this.title = 'Lighting';
                this.sceneNumber = '05';
                break;
            case 'offstage':
                this.lottieScroll = new LottieScroll(
                {
                    target: this.target,
                    speed: "medium",
                    path:  window.location.href + "/lottie/scenes/s6.json",
                    scrub: 1,
                    id: 'offstage',
                    triggerPoints:
                [
                    [1, 29, 'modal', 'none'],
                    [30, 80, 'modal', 'one' ],
                    [81, 99, 'modal', 'none'],
                ]
                });

                this.lottieScroll.on('loaded', () => {
                    self.trigger('loaded');
                })

                this.title = 'Offstage';
                this.sceneNumber = '06';
                break;
            case 'cast-and-creatives':
                    this.lottieScroll = new LottieScroll(
                        {
                            target: this.target,
                            speed: "medium",
                            path:  window.location.href + "/lottie/scenes/s7.json",
                            scrub: 1,
                            id: 'cast-and-creatives',
                            triggerPoints:
                    [
                        [1, 4, 'modal', 'none'],
                        [5, 20, 'modal', 'one' ],
                        [21, 25, 'modal', 'none'],
                        [32, 38, 'modal', 'two' ],
                        [39, 39.9, 'modal', 'none'],
                        [40, 48, 'modal', 'three'],
                        [48.1, 55, 'modal', 'none'],
                        [56, 66, 'modal', 'four' ],
                        [67, 74, 'modal', 'none'],
                        [75, 87, 'modal', 'five' ],
                        [88, 99, 'modal', 'none'],
                    ]
                        });
        
                        this.lottieScroll.on('loaded', () => {
                            self.trigger('loaded');
                        })

                        this.title = 'Cast and Creatives';
                        this.sceneNumber = '07';
                    break; 
            case 'the-performance':
                this.lottieScroll = new LottieScroll(
                    {
                        target: this.target,
                        speed: "medium",
                        scrub: 1,
                        id: 'the-performance',
                        path:  window.location.href + "/lottie/scenes/s8.json",

                        triggerPoints:
                    [
                        [1, 99, 'modal', 'none'],
                    ]
                    });
    
                    this.lottieScroll.on('loaded', () => {
                        self.trigger('loaded');
                    })

                    this.title = 'The Performance';
                    this.sceneNumber = '08';
                break;
            case 'back-of-house':
                this.lottieScroll = new LottieScroll(
                    {
                        target: this.target,
                        speed: "medium",
                        scrub: 1,
                        id: 'back-of-house',
                        path:  window.location.href + "/lottie/scenes/s9.json",
                        triggerPoints:
                    [
                        [1, 99, 'modal', 'none'],
                    ]
                    });
    
                    this.lottieScroll.on('loaded', () => {
                        self.trigger('loaded');
                    })

                    this.title = 'Back Of House';
                    this.sceneNumber = '09';
                break;  
            case 'coming-soon':
                this.lottieScroll = new LottieScroll(
                    {
                        target: this.target,
                        speed: "medium",
                        scrub: 1,
                        path:  window.location.href + "/lottie/scenes/s10.json",
                        id: 'coming-soon',
                        triggerPoints:
                    [
                        [1, 99, 'modal', 'none'],
                       
                    ]
                    });
    
                    this.lottieScroll.on('loaded', () => {
                        self.trigger('loaded');
                    })

                    this.title = 'Coming Soon';
                    this.sceneNumber = '10';
                break;     
            default:
                break;
        }

    }
}