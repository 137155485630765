export default class Buttons {
    constructor()
    {
        this.Buttons = document.querySelectorAll('.button--hub');

        if (this.Buttons){
            this.Buttons.forEach(button => {
                ["mouseenter", "mouseout"].forEach(evt => {
                  button.addEventListener(evt, e => {
                    let parentOffset = button.getBoundingClientRect(),
                        relX = e.clientX - parentOffset.left,
                        relY = e.clientY - parentOffset.top;
            
                    const span = button.getElementsByTagName("span")
                    if (span)
                    {
                        span[0].style.top = relY + "px";
                        span[0].style.left = relX + "px";
                    }            
                  });
                });
              });
        }

        this.Icons = document.querySelectorAll('.nav__icon')

        if (this.Icons){

          this.Icons.forEach(button => {
              ["mouseenter", "mouseout"].forEach(evt => {
                button.addEventListener(evt, e => {
                  let parentOffset = button.getBoundingClientRect(),
                      relX = e.clientX - parentOffset.left,
                      relY = e.clientY - parentOffset.top;
                  
                  const span = button.querySelector('.overlay');
          
                  span.style.top = relY + "px";
                  span.style.left = relX + "px";
                });
              });
            });
      }

      this.Copy = document.querySelectorAll('.nav__copy-icon');

      if (this.Copy){

        this.Copy.forEach(button => {
            ["mouseenter", "mouseout"].forEach(evt => {
              button.addEventListener(evt, e => {
                let parentOffset = button.getBoundingClientRect(),
                    relX = e.clientX - parentOffset.left,
                    relY = e.clientY - parentOffset.top;
                
                const span = button.querySelector('.overlay');
        
                span.style.top = relY + "px";
                span.style.left = relX + "px";
              });
            });
          });
    }

    this.closeModal = document.querySelectorAll('.modal__close');

    if (this.closeModal){

      this.closeModal.forEach(button => {
          ["mouseenter", "mouseout"].forEach(evt => {
            button.addEventListener(evt, e => {
              let parentOffset = button.getBoundingClientRect(),
                  relX = e.clientX - parentOffset.left,
                  relY = e.clientY - parentOffset.top;
              
              const span = button.querySelector('.overlay');
      
              span.style.top = relY + "px";
              span.style.left = relX + "px";
            });
          });
        });
  }

    }

}