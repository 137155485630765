import EventEmitter from "./EventEmitter";

export default class Loader extends EventEmitter {
    constructor()
    {
        super()

        this.items = 
        {
            images : false,
            fonts : false
        }

        let self = this;
        this.setLoaded('images');

        document.fonts.ready.then(this.setLoaded('fonts'));
    }
    setLoaded(obj)
    {
        this.items[obj] = true;

        this.isLoaded = Object.values(this.items).every(
            value => value === true
        );

        if (this.isLoaded)
        {
            this.trigger('complete');
        } 
    }
    addItem(name)
    {
        this.items[name] = false;
    }
}