import { gsap } from "gsap/all";
import { getRandomInt, getSiblings } from "../utils/utils";

export default class Cursor {
    constructor()
    {


        this.scrollPos = 0;
    
        this.noScales = document.querySelectorAll('.no-scale');

        this.smallCursor = document.querySelectorAll('[data-cursor="small"]');

        this.hoverItems = document.querySelectorAll('.cursor__item');

        gsap.set('.cursor', {
            xPercent: -50,
            yPercent: -50,
            scale: 0,
        })

        this.cursor = document.querySelector('.cursor');


        this.pos = 
        {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        }

        this.mouse = 
        {
            x: this.pos.x,
            y: this.pos.y
        }

        this.posPercent =
        {
            x: window.innerWidth / this.pos.x,
            y: window.innerHeight / this.pos.y
        }

        this.init();

    }
    init()
    {
        if (window.app.isMobile)
        {
            this.mobileInit();
        }
        else
        {
            this.desktopInit();
        }
    }
    mobileInit()
    {
        this.hoverItems = document.querySelectorAll('.cursor__item');

        this.hoverItems.forEach((hoverItem)=>
        {
            
            hoverItem.removeEventListener('touchstart', this.mobileEnter(hoverItem));
            hoverItem.removeEventListener('touchend', this.mobileLeave(hoverItem));
            hoverItem.removeEventListener('mouseenter', this.desktopEnter(hoverItem));
            hoverItem.removeEventListener('mouseleave', this.desktopLeave(hoverItem));

            hoverItem.addEventListener('touchstart', (event)=>
            {
                event.stopPropagation();
                if (event.cancelable) event.preventDefault();
                this.mobileEnter(hoverItem);
            })

            hoverItem.addEventListener('touchend', (event)=>
            {
                event.stopPropagation();
                if (event.cancelable) event.preventDefault();
                this.mobileLeave(hoverItem)
            })
        })

    }
    desktopInit()
    {
        this.hoverItems = document.querySelectorAll('.cursor__item');
        
        window.addEventListener('mousemove', e => {
            this.mouse.x = e.x;
            this.mouse.y = e.y;
        })

        this.hoverItems.forEach((hoverItem)=>{

            hoverItem.removeEventListener('mouseenter', this.desktopEnter(hoverItem));
            hoverItem.removeEventListener('mouseleave', this.desktopLeave(hoverItem));
            hoverItem.removeEventListener('touchstart', this.mobileEnter(hoverItem));
            hoverItem.removeEventListener('touchend', this.mobileLeave(hoverItem));

            hoverItem.addEventListener('mouseenter', () => {
                this.desktopEnter(hoverItem);
            })

            hoverItem.addEventListener('mouseleave', () => {
                this.desktopLeave(hoverItem);
            })
        });

        let mm = gsap.matchMedia();

        mm.add("(min-width: 1024px)", (context) => 
        {
            window.addEventListener('scroll', ()=>
            {
                if ((document.body.getBoundingClientRect()).top > this.scrollPos)
                {
                    this.cursor.classList.add('up');
                    this.cursor.classList.remove('down');
                }
                else
                {
                    this.cursor.classList.add('down');
                    this.cursor.classList.remove('up');
                }
                this.scrollPos = (document.body.getBoundingClientRect()).top;
            })

        })

        this.noScales.forEach((item)=>
        {
            item.addEventListener('mouseenter', ()=>
            {
                this.scaleAnimation(this.cursor, 0);
                this.scaleAnimation(this.cursor, 0);
            })
            item.addEventListener('mouseleave', ()=>
            {
                this.scaleAnimation(this.cursor, 1);
                this.scaleAnimation(this.cursor, 1);
            })

        })


        this.speed = 0.35;

        this.xSet = gsap.quickSetter(this.cursor, "x", "px");
        this.ySet = gsap.quickSetter(this.cursor, "y", "px");

        this.xPercentSet = gsap.quickSetter('html', '--spotx', "%");
        this.yPercentSet = gsap.quickSetter('html', '--spoty', "%");

        const updateCursor = () =>
        {
            const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio());

            this.pos.x += (this.mouse.x - this.pos.x) * dt;
            this.pos.y += (this.mouse.y - this.pos.y) * dt;

            this.xSet(this.pos.x);
            this.ySet(this.pos.y);
        }
        
        gsap.ticker.add(updateCursor);
        this.grow()


        this.smallCursor.forEach((item)=>
        {
            item.addEventListener('mouseenter', ()=>
            {   
                this.cursor.classList.add('small');
                this.scaleAnimation(this.cursor, 0.2);
            })
            item.addEventListener('mouseleave', ()=>
            {
                this.cursor.classList.remove('small');
                this.scaleAnimation(this.cursor, 1);
                this.scaleAnimation(this.cursor, 1);
            })
        })


    }
    getRelativePosition = (e, image, target) => 
    {   
        let rect = e.target.getBoundingClientRect();
        let relativeX = e.clientX - rect.left; 

        let percentageX = ((relativeX / target.offsetWidth) * 100) - 50;

        gsap.to(image.parentNode, {
            x :`${percentageX}%`,
            duration: 0.1,
            ease: "Power3.easeOut"
        })
    }
    desktopEnter(item)
    {
        let image = item.querySelector('img');
        this.hoverItems.forEach((item)=>
        {
            item.classList.remove('active');
        })

        this.cursor.classList.add('small');
        this.scaleAnimation(this.cursor, 0.2);

        this.checkTop(item);

        this.rotate(image);

        item.classList.add('active');

        item.addEventListener('mousemove', (e)=> {
            this.getRelativePosition(e, image, item);
        })

        window.addEventListener('scroll', ()=> {
            this.checkTop(item);
        })
    }
    desktopLeave(item)
    {
        let image = item.querySelector('img');
        this.cursor.classList.remove('small');
        this.scaleAnimation(this.cursor, 1);
        
        item.classList.remove('active');

        item.removeEventListener('mousemove', (e) => {
            this.getRelativePosition(e, image, item);
        })
        
        window.removeEventListener('scroll', ()=>
        {
            this.checkTop(hoverItem);
        })
    }
    mobileEnter(item)
    {
        let image = item.querySelector('img');
        this.clearHovers();
        this.checkTop(item);
        this.rotate(image);

        item.classList.add('active');
    }
    mobileLeave(item)
    {
        item.classList.remove('active');
    }
    shrink()
    {
        this.scaleAnimation(this.cursor, 0);
    }
    grow()
    {
        this.scaleAnimation(this.cursor, 1);
    }
    checkTop(el)
    {
        if (window.app.isMobile)
        {
            let wordPos = el.getBoundingClientRect().top;
            let navBorder = document.querySelector('.nav__border--top').offsetHeight;
            let media = el.querySelector('img').offsetHeight;
            let mediaHeight = media + navBorder + 40;
            if (wordPos < mediaHeight) {
                el.classList.add("top");
              } else {
                el.classList.remove("top");
              }
        }
        else
        {
            let navBorder = document.querySelector('.nav__border--top').offsetHeight;
            let media = el.querySelector('img').offsetHeight;
            let mediaHeight = media + navBorder + 40;
            if (this.mouse.y < mediaHeight) {
                el.classList.add("top");
              } else {
                el.classList.remove("top");
              }
        }
    }
    rotate(el)
    {
        let int = getRandomInt(-4, 4);
        el.style.setProperty('--rotation', `${int}deg`)
    }
    clearHovers()
    {
        this.hoverItems.forEach((item)=>
        {
            item.classList.remove('active');
        })
    }
    scaleAnimation(el, amt) {
        gsap.to(el, {
          duration: 0.6,
          scale: amt,
          ease: "Power3.easeOut",
        });
    }
    setImage(el)
    {
        let src = el.getAttribute("data-image");
        let image = document.querySelector(`#${src}`);
        let siblings = getSiblings(image);

        if (image.id == src) {
        gsap.set(image, {
            zIndex: 4,
            opacity: 1,
        });
        siblings.forEach((i) => {
            gsap.set(i, {
            zIndex: 1,
            opacity: 0,
            });
        });
        }
    }

}