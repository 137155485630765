/**
 * Shared utilities.
 */

export const lazyLoadImage = (img) => {
	const src = img.getAttribute('data-src');
	const type = img.getAttribute('loading');
	if(type == 'lazy'){
		img.classList.add('lazy--loaded');
	}
	if (!src) { return; }
	var image = new Image();
	image.src = src;
	image.onload = function () {
		img.src = image.src;
		img.classList.add('lazy--loaded');
	}
}


export const lazyBackground = (img) => {
	const src = img.getAttribute('data-background');
	if (!src) { return; }
	var image = new Image();
	image.src = src;
	image.onload = function () {
		img.style.backgroundImage = 'url("' + image.src + '")';
		img.classList.add('lazy--loaded');
	}
}

// Linear interpolation
export const lerp = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position
export const getMousePos = (e) => {
  let posx = 0;
  let posy = 0;
  if (!e) e = window.event;
  if (e.clientX || e.clientY) {
    posx = e.clientX;
    posy = e.clientY;
  }
  return { x: posx, y: posy };
};

// Get sibilings
export const getSiblings = (e) => {
  // for collecting siblings
  let siblings = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;
  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
};

export const getDimensions = (el)=>
{
    return el.classList[0] + ' dimensions are ' + 'width - ' + el.offsetWidth + ' and height - ' + el.offsetHeight
}

export const convertToSlug = (text) =>
{
  return text.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
}

export const convertRange = ( value, r1, r2 ) => { 
  return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
}

export const documentReady = (fn) => {
   // see if DOM is already available
   if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    document.body.classList.add('loaded');
    setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export const getRandomInt = (min, max) =>
{
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getCumulativeOffset = (element)=>
{
    let top = 0, left = 0;

    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);

    return {
        top: top,
        left: left
    };
}